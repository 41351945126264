<template>
  <l-tela titulo="Dados da empresa">
    <l-form :salvar="salvar">
      <i-basico
        id="nomeCargo"
        label="Nome"
        v-model="form.nome"
        :validations="{ required: true, min: 3 }"
      />

      <i-contato
        id="contato"
        label="Contato"
        v-model="form.contato"
        :validations="{ required: true, validaLenCel: true }"
      />

      <i-basico
        id="email"
        label="E-mail"
        v-model="form.email"
        :validations="{ required: true, email: true }"
      />

      <template v-slot:botoes>
        <v-btn color="primary" type="submit">Salvar</v-btn>
      </template>
    </l-form>
  </l-tela>
</template>

<script>
import store from '@/store'
import empresaService from '@/services/empresa.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      novoRegistro: true,
      form: {
        _id: null,
        nome: '',
        contato: '',
        email: '',
      },
    }
  },
  mounted() {
    this.carregar()
  },
  methods: {
    async carregar() {
      const dadosUsuario = store.getters['dadosUsuario']
      if (dadosUsuario && dadosUsuario.empresa) {
        const empresaId = dadosUsuario.empresa
        this.mostrarLoading()
        try {
          this.form = await empresaService.buscaPorId(empresaId)
        } catch (error) {
          this.mostraNotificacao(error)
        } finally {
          this.fecharLoading()
        }
      }
    },
    async salvar() {
      this.mostrarLoading()
      try {
        if (this.form._id) {
          await empresaService.atualizar({ ...this.form })
          this.alertaDadosSalvos()
        }
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
  },
}
</script>
